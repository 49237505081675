import React from 'react';

import { useTranslation } from '@external/react-i18next';

export const Korea = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      {t(
        'korea-country.note',
        'We are unable to process club dues payments from your country online at this time. Please contact your local Rotary office for assistance.'
      )}
    </div>
  );
};
