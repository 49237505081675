import React from 'react';

import Alert from '@rotaryintl/harris-alert';

import { useTranslation } from '@external/react-i18next';

export const Kosovo = () => {
  const { t } = useTranslation();
  return (
    <Alert variant="info" isDismissible={false}>
      {t(
        'kosovo-country.note',
        'Please contact your <a href="https://my.rotary.org/en/contact/representatives" target="_blank"><b>financial representative</b></a> for information about your club’s invoice.'
      )}
    </Alert>
  );
};
