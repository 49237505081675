import React from 'react';

import { useTranslation } from '@external/react-i18next';

export const India = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-4 flex flex-col gap-4">
      <div>
        {t(
          'india-country.note',
          'Indian clubs cannot make online payments from MyRotary.org due to tax and regulatory matters.'
        )}
      </div>
      <div>
        {t(
          'india-country.online-instructions',
          'Clubs are requested to make the electronic payment through NEFT/RTGS using their own virtual account number of the club, please see invoice for instructions.'
        )}
      </div>
      <div>
        {t(
          'india-country.offline-instructions',
          'Clubs also can send cheque/draft payment with the invoice number/club name to the local RI Office:'
        )}
      </div>
      <div>
        <div>
          {t(
            'india-country.office-name',
            'Rotary International South Asia Office'
          )}
        </div>
        <div>
          {t(
            'india-country.address-line-one',
            'Pullman/Novotel Commercial Tower'
          )}
        </div>
        <div>
          {t('india-country.address-line-two', 'First Floor I Asset No.2')}
        </div>
        <div>
          {t('india-country.address-line-three', 'Hospitality District')}
        </div>
        <div>
          {t('india-country.address-city', 'Aerocity (Near IGI Airport)')}
        </div>
        <div>{t('india-country.address-state', 'New Delhi 110037')}</div>
        <div>{t('india-country.address-country', 'India')}</div>
      </div>
    </div>
  );
};
