export enum CountryId {
  RIBI = 'RIBI',
  IMN = 'IMN', // 'Isle of Man'
  GGY = 'GGY', // 'Guernsey-Channel Islands'
  SCO = 'SCO', // 'Scotland'
  JEY = 'JEY', // 'Jersey-Channel Islands'
  IRL = 'IRL', // 'Ireland'
  WAL = 'WAL', // 'Wales'
  GBR = 'GBR', // 'England'
  GIB = 'GIB', // 'Gibraltar'
  NIR = 'NIR', // 'Northern Ireland'
  ALA = 'ALA', // 'Aland Island'
  IND = 'IND', // 'India'
  KOR = 'KOR', // 'Korea, Republic of'
  XKX = 'XKX', // 'Kosovo'
  PHL = 'PHL', // 'Philippines'
}

export const RIBI_COUNTRY_LIST: string[] = [
  CountryId.IMN,
  CountryId.GGY,
  CountryId.SCO,
  CountryId.JEY,
  CountryId.IRL,
  CountryId.WAL,
  CountryId.GBR,
  CountryId.GIB,
  CountryId.NIR,
];

export const noDonateWidgetCountries: string[] = [
  CountryId.ALA,
  CountryId.IND,
  CountryId.KOR,
  CountryId.XKX,
  CountryId.PHL,
];

export const noClubInfoCountries: string[] = [CountryId.ALA, CountryId.XKX];
